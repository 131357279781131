<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="page-title">Nova tarefa na lista <span class="bucket-name">{{ bucketName }}</span></h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <form name="form" @submit.prevent="createTask">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">Selecione o tipo da atividade</div>
              </div>
              <div class="row mt-4 mb-5">
                <div class="col text-center">
                  <div class="card card-option" style="width: 18rem;" @click="selectType('p')" :class="{ active: type=='p' }">
                    <img src="@/assets/icons/unstarted_p.svg" class="card-img-top img-responsive" alt="Tarefa pessoal">
                    <div class="card-body">
                      <p class="card-text">Tarefa pessoal</p>
                    </div>
                  </div>
                </div>
                <div class="col text-center">
                  <div class="card card-option" style="width: 18rem;" @click="selectType('w')" :class="{ active: type=='w' }">
                    <img src="@/assets/icons/unstarted.svg" class="card-img-top img-responsive" alt="Tarefa profissional">
                    <div class="card-body">
                      <p class="card-text">Tarefa profissional</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="description">Descrição da tarefa</label>
                    <input required id="description" type="text" maxlength="140" class="form-control" placeholder="Fazer aquela coisa incrível" v-model="description">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row actions">
            <div class="col text-right">
              <router-link class="btn btn-outline-secondary mr-1" to="/">Cancelar</router-link>
              <button type="submit" class="btn btn-primary">Criar tarefa</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { getBucketName } from '@/utils'
import { TASK_STATUS, TASK_TYPE } from '@/enumerations'

export default {
  data () {
    return {
      bucket: this.$route.params.bucket,
      bucketName: this.$router.currentRoute.meta.title,
      description: null,
      type: TASK_TYPE.WORK
    }
  },
  mounted () {
    this.bucketName = getBucketName(this.$route.params.bucket)
  },
  methods: {
    selectType (type) {
      this.type = type
    },
    async createTask () {
      const { type, description } = this

      this.showModal = false

      try {
        const db = this.$firebase.firestore()
        const tasksRef = await db.collection('customers').doc(window.uid).collection('tasks')

        const task = {
          d: description,
          t: type,
          s: TASK_STATUS.UNSTARTED,
          b: this.$route.params.bucket,
          cd: new Date().getTime()
        }
        await tasksRef.add(task)

        this.$root.$emit('Notification::show', { type: 'success', message: 'Tarefa criada com sucesso' })

        const origin = this.$router.options.routes.find(e => e.meta.bucket === this.bucket)
        this.$router.push({ name: origin.name })
      } catch (error) {
        this.$root.$emit('Notification::show', { type: 'danger', message: 'Erro ao criar a tarefa' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-option {
  cursor: pointer;
  padding: 20px 0;
  margin: 0 auto;
  float: none;

  &.active, &:hover {
    background-color: var(--ligther);
  }
}
.card-img-top {
  height: 25px;
  width: 25px;
  margin: 0 auto;
  margin-top: 10px;
}
.bucket-name {
  font-weight: bold;
  color: var(--featured);
}
</style>
