<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="page-title">Editar tarefa</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <form name="form" @submit.prevent="updateTask">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">Tipo da tarefa</div>
              </div>
              <div class="row my-4">
                <div class="col text-center">
                  <div class="option" @click="selectType('p')" :class="{ active: task.type=='p' }">
                    <img src="@/assets/icons/unstarted_p.svg" class="card-img-top img-responsive" alt="Tarefa pessoal">
                    <div class="card-body">
                      <p class="card-text">Tarefa pessoal</p>
                    </div>
                  </div>
                </div>
                <div class="col text-center">
                  <div class="option" @click="selectType('w')" :class="{ active: task.type=='w' }">
                    <img src="@/assets/icons/unstarted.svg" class="card-img-top img-responsive" alt="Tarefa profissional">
                    <div class="card-body">
                      <p class="card-text">Tarefa profissional</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="description">Descrição da tarefa</label>
                    <input required id="description" type="text" maxlength="140" class="form-control" placeholder="Fazer aquela coisa incrível" v-model="task.description">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">Status da tarefa</div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item" @click="selectStatus('u')" :class="{ active: task.status=='u' }">
                      <img src="@/assets/icons/unstarted.svg" alt="Não iniciada">
                      <span>Não iniciada</span>
                    </li>
                    <li class="list-group-item" @click="selectStatus('s')" :class="{ active: task.status=='s' }">
                      <img src="@/assets/icons/started.svg" alt="Iniciada">
                      <span>Iniciada</span>
                    </li>
                    <li class="list-group-item" @click="selectStatus('p')" :class="{ active: task.status=='p' }">
                      <img src="@/assets/icons/pushed.svg" alt="Fazer amanhã">
                      <span>Fazer amanhã</span>
                    </li>
                    <li class="list-group-item" @click="selectStatus('b')" :class="{ active: task.status=='b' }">
                      <img src="@/assets/icons/blocked.svg" alt="Bloqueada">
                      <span>Bloqueada</span>
                    </li>
                    <li class="list-group-item" @click="selectStatus('ub')" :class="{ active: task.status=='ub' }">
                      <img src="@/assets/icons/blocked_unstarted.svg" alt="Bloqueada antes de começar">
                      <span>Bloqueada antes de começar</span>
                    </li>
                    <li class="list-group-item" @click="selectStatus('x')" :class="{ active: task.status=='x' }">
                      <img src="@/assets/icons/canceled.svg" alt="Cancelada">
                      <span>Cancelada</span>
                    </li>
                    <li class="list-group-item" @click="selectStatus('c')" :class="{ active: task.status=='c' }">
                      <img src="@/assets/icons/completed.svg" alt="Finalizada">
                      <span>Finalizada</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row actions">
            <div class="col">
              <button type="button" class="btn btn-outline-danger" @click="deleteTask"><i class="fas fa-times"></i> Excluir tarefa</button>
            </div>
            <div class="col text-right">
              <router-link class="btn btn-outline-secondary mr-1" to="/">Cancelar</router-link>
              <button type="submit" class="btn btn-primary">Atualizar tarefa</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      task: {
        bucket: '',
        id: '',
        description: '',
        type: '',
        status: ''
      }
    }
  },
  created () {
    const { id, description, type, status, bucket } = this.$route.params
    this.task = { id, description, type, status, bucket }
  },
  methods: {
    selectStatus (status) {
      this.task.status = status
    },
    selectType (type) {
      this.task.type = type
    },
    async updateTask () {
      const { type, status, description } = this.task

      try {
        const db = this.$firebase.firestore()
        const docRef = await db.collection('customers').doc(window.uid).collection('tasks').doc(this.task.id)
        const doc = await docRef.get()
        const task = {
          d: description,
          t: type,
          s: status,
          ud: new Date().getTime()
        }
        const objetoFinal = { ...doc.data(), ...task }
        await docRef.set(objetoFinal)

        this.$root.$emit('Notification::show', { type: 'success', message: 'Tarefa atualizada com sucesso' })

        const origin = this.$router.options.routes.find(e => e.meta.bucket === this.task.bucket)
        this.$router.push({ name: origin.name })
      } catch (error) {
        this.$root.$emit('Notification::show', { type: 'danger', message: 'Erro ao atualizar a tarefa' })
      }
    },
    async deleteTask () {
      try {
        const db = this.$firebase.firestore()
        await db.collection('customers').doc(window.uid).collection('tasks').doc(this.task.id).delete()

        this.$root.$emit('Notification::show', { type: 'success', message: 'Tarefa excluída com sucesso' })

        const origin = this.$router.options.routes.find(e => e.meta.bucket === this.task.bucket)
        this.$router.push({ name: origin.name })
      } catch (error) {
        this.$root.$emit('Notification::show', { type: 'danger', message: 'Erro ao excluir a tarefa' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  margin-bottom: 5px;
  padding: 6px;
  background-color: var(--ligther);

  border-left: 4px solid var(--light-medium);

  &:hover, &.active {
    border-left: 4px solid var(--featured);
    cursor: pointer;
    font-weight: bold;
    color: var(--featured-dark);
  }

  &.active {
    border: 1px solid var(--featured);
    border-left: 4px solid var(--featured);
  }

  img {
    height: 25px;
    width: 25px;
    margin: 6px;
  }
}

.option {
  cursor: pointer;
  padding: 10px 0 5px 0;
  margin: 0 auto;
  float: none;

  max-width: 350px;

  &.active, &:hover {
    background-color: var(--ligther);

    border-left: 4px solid var(--featured);
    cursor: pointer;
    font-weight: bold;
    color: var(--featured-dark);
  }
}
.card-img-top {
  height: 25px;
  width: 25px;
  margin: 0 auto;
  margin-top: 10px;
}
</style>
