<template>
  <div class="login-area">
    <form class="col-10 col-md-4 form-login" @submit.prevent="login">
      <div class="card">
        <div class="card-header text-center">
          <h1 class="mb-0">Jayio</h1>
        </div>
        <div class="card-body">
          <div class="form-group">
            <input required type="email" class="form-control" placeholder="E-mail" v-model="email">
          </div>
          <div class="form-group">
            <input required type="password" class="form-control" placeholder="Password" v-model="password">
          </div>
          <button class="btn btn-primary btn-block">
            <template v-if="loading" :disabled="loading">
              Entrando <i class="fa fa-spinner fa-spin"></i>
            </template>
            <template v-else>
              Entrar <i class="fa fa-sign-in-alt"></i>
            </template>
          </button>
        </div>
        <div class="card-footer text-center">
          <router-link to='/register'>Não tem uma conta? Registre-se</router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      email: '',
      password: ''
    }
  },
  methods: {
    async login () {
      try {
        this.loading = true

        const { email, password } = this
        const res = await this.$firebase.auth().signInWithEmailAndPassword(email, password)

        window.uid = res.user.uid

        this.$router.push({ name: 'today' })
      } catch (error) {
        let message = ''
        switch (error.code) {
          case 'auth/user-not-found':
            message = 'Não foi possível localizar o usuário.'
            break
          case 'auth/wrong-password':
            message = 'Senha inválida'
            break
          default:
            message = 'Não foi possível fazer login, tente novamente'
        }
        this.$root.$emit('Notification::show', { message })
      } finally {
        this.loading = false
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (window.uid) {
        vm.$router.push({ name: 'today' })
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.login-area {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-login {
  h1 {
    font-size: 1.5rem;
  }
  .card {
    color: var(--darker);
  }
}
.card-footer {
  font-size: 0.9rem;
}
</style>
