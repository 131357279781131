export const TASK_BUCKET = Object.freeze({
  ARCHIVE: 'a',
  BACKLOG: 'b',
  TODAY: 't',
  PUSHED: 'p'
})

export const TASK_STATUS = Object.freeze({
  UNSTARTED: 'u',
  COMPLETED: 'c',
  STARTED: 's',
  BLOCKED: 'b',
  PUSHED: 'p',
  CANCELED: 'x',
  UNSTARTED_BLOCKED: 'ub'
})

export const TASK_TYPE = Object.freeze({
  PERSONAL: 'p',
  WORK: 'w'
})
